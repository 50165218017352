import { OcadoEnvironment } from "../app/shared/shared.types";
import config from '../auth_config_dev.json';

const { domain, clientId, authorizationParams: { audience, scope }, apiUri, errorPath } = config as {
  domain: string;
  clientId: string;
  authorizationParams: {
    audience?: string;
    scope?: string;
  },
  apiUri: string;
  errorPath: string;
};

export const environment = {
  production: false,
  gcpProjectId: 'orl-dev-coml-prj01',
  guid: {
    modellingToolModelMetaDataGuid: "887554b5-e31d-4acd-8412-c563e895451f",
    modellingToolParamsGuid: "d5ced979-1fe0-447f-9ad5-8fb45384a0b3",
    customModellingToolParamsGuid: "d5ced979-1fe0-447f-9ad5-8fb45384a0b3",
    modelling_tool_newGuid: "6136388d-fb07-47f7-b186-75decc66a77f",
    modelling_tool_historyGuid: "17d561f5-f75a-4301-bb5a-89a2f14fe49d",
  },
  baseApi: {
    case: 'https://dev-commercial.ocadohub.com',
    document: 'https://dev-commercial.ocadohub.com',
    notification: 'https://dev-commercial.ocadohub.com',
    plan: 'https://dev-commercial.ocadohub.com',
    suphub: 'https://dev.ocadohub.com',
    promo: 'https://dev-promo.ocadohub.com',
    commercial: 'https://dev-commercial.ocadohub.com',
    media: 'https://dev-ocado.zitcha.app',
    learn: 'https://ocadohub.learnstaging.link',
    walnut: 'https://orl.learnstaging.link',
  },
  environment: OcadoEnvironment.DEV,
  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
      scope: scope,
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },
  box: {
      folderId: 275024932166,
      folderName: "DEV Ocado Retail"
  }
};
